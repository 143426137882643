<template>
    <div class="campus">
        <div class="active">
            <div class="active-text">LEAGUE SCHOOL DISPLAY </div>
            <div class="active-text">盟校展示</div>
        </div>
        <div class="center">
            <div class="center-title">
                <span></span>
                <div>
                    懂艺术 绘生活
                </div>
            </div>
            <div class="center-list">
                <div v-for="(item,index) in campusList" :key="item.id" @click="active=index" class="center-list-single"
                    :class="active == index?'msg':''">
                    <p>{{item.name}}</p> <i class="el-icon-d-arrow-right"></i>
                </div>
            </div>

            <!-- 轮播图 -->
            <!-- Swiper -->
            <div class="swiper-container backgroundfff">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in campusList[active].images.split(',')" :key="item">
                        <img :src="$imgURL+ item" />
                    </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
            </div>

        </div>
    </div>
</template>

<script>
import Swiper from 'swiper'
export default {
    name: 'index',
    data() {
        return {
            active: 0,
            campusList: [
            ]
        }
    }, created() {

    },
    mounted() {
        this.campusList2()
    },
    methods: {
        myswiper() {
            var swiper = new Swiper('.swiper-container', {
                pagination: {
                    el: '.swiper-pagination',

                }, autoplay: {
                    delay: 3000, //3秒切换一次
                    pauseOnMouseEnter: true, //悬停停止切换
                },
            });
        },
        // 获取运营驻店指导轮播图
        campusList2() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "/api/index/schoolList",
            }).then((res) => {

                this.campusList = res.data.data
                console.log("轮播图列表", this.campusList);

                this.$nextTick(() => {
                    this.myswiper()
                })
            });
        }

    }
}
</script>

<style lang="scss">
@import url("../../node_modules/swiper/css/swiper.css");

.campus {
    .active {
        padding-top: 1.64rem;
        width: 100%;
        height: 5.46rem;
        background-color: #FFFFFF;
        display: flex;
        flex-flow: column;
        justify-content: center;

        &-text {
            text-align: center;
            width: 66%;
            margin: 0 auto;

            &:nth-child(1) {
                font-size: .66rem;
                font-family: Galvji;
                font-weight: bold;
                color: #000000;
            }

            &:nth-child(2) {
                font-size: .42rem;
                font-family: Heiti SC;
                font-weight: 300;
                color: #000000;
                margin: .68rem auto;
            }
        }
    }


    .center {
        width: 90%;
        margin-left: 10%;
        position: relative;
        margin-top: .18rem;

        &-title {
            height: .82rem;
            background-color: #FFFFFF;
            display: flex;
            align-items: center;

            span {
                display: inline-block;
                width: .08rem;
                height: 100%;
                background: #FDD23E;
            }

            div {
                display: inline-block;
                font-size: .3rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FDD23E;
                line-height: .82rem;
                margin-left: .32rem;
            }
        }


        &-list {
            width: 7.54rem;
            height: 9.19rem;
            background: #F1F1F1;
            border-radius: .2rem;
            display: flex;
            flex-flow: column;

            .msg {
                background: #FDD23E;
                border-radius: 20px;

                i {
                    display: block;
                }
            }

            &-single {
                height: 1rem;
                display: flex;
                align-items: center;
                padding-left: 1.15rem;
                cursor: pointer;

                p {
                    font-size: .24rem;
                    font-family: Heiti SC;
                    font-weight: 500;
                    color: #565656;
                }

                i {
                    display: none;
                    color: #FFFFFF;
                    margin-left: .3rem;
                }
            }
        }
    }

    // 轮播图
    .swiper-container {
        width: 12rem;
        height: 9.5rem;
        position: absolute;
        right: 0;
        top: 0;

        .swiper-pagination-bullet {
            width: .26rem !important;
            height: .26rem !important;
            background-color: #E9E9E9 !important;
            margin-right: .43rem !important;
        }

        .swiper-pagination-bullet-active {
            background-color: #FDD23E !important;
        }

        .swiper-wrapper {
            height: 83%;
        }
    }


    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

    }

    .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>